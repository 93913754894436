import restaurant from "./images/richland.jpg";
import img_card from "./images/sa.jpg";
import imgheadermb from "./images/f1m.png";
import imgheader from "./images/f1.png";
import imgheadermiini from "./images/b1.jpg";
import imgheadermiinimb from "./images/b1.jpg";
import logo from './images/logo.png'
import mikey from './images/inv.png'
import mikeym from './images/invm.png'
const data = {
    introData: [{
        copilul: "Sofia & Amelia",
        familia: "fam. Rusanovschi",
        logo: logo,
        tata: "Vitali",
        mama: "Mihaela",
        data: "13 Iulie 2024",
        data_confirmare: "1 iulie 2024",
        savedata: "~ Vă invită! ~",
        imgdesktop: imgheader,
        imgmobil: imgheadermb,
        email: "mihaela.rusanovschi1988@gmail.com", 
       tel: "+37368066053",
       phone: "tel:+37368066053",
       viber: "viber://chat?number=%2B37368066053",
       whatsapp: "https://wa.me/+37368066053",
       messenger: "https://www.messenger.com/t/xxx",
       tel1: "+373 697 40 712",
       phone1: "tel:+37369740712",
       viber1: "viber://chat?number=%2B37369740712",
       whatsapp1: "https://wa.me/+37369740712",
       messenger1: "https://www.messenger.com/t/petru.bucatari",
    }],
    cardData: [
   
        {
            id: 2,
            img: restaurant,
            title: "Petrecerea Evenimentului",
            localul: "Restaurantul",
            name: "Richland, sala Safir",
            data: "13 iulie 2024, sâmbătă, ora 18:00",
            adress: "Grătiești, Chișinău",
            harta: "https://maps.app.goo.gl/xZ2DyZ1QvSGLiMTEA",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10866.789736928076!2d28.8271568!3d47.0853967!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97d1c8588b3f7%3A0x105ba631571eda8b!2sRichland!5e0!3m2!1sro!2s!4v1718650792906!5m2!1sro!2s"   
        },

    ],
    cardImg: [
   
        {
            
            imginv: mikey,
            imginvm: mikeym,
        },

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "",
            title2: "",
            message: "Copiii sunt un dar al lui Dumnezeu, un motiv de a crede în minuni, sunt pur și simplu fericirea pe Pământ.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: "",
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: '',
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "Salutare!",
            message: "Dacă ați ajuns aici înseamnă că ținem la voi și ne-am bucura enorm dacă veți fi alături de noi într-o zi atât de specială!",
        }
    ],

}

export default data;